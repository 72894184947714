import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {
  Box,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  Page: {
    background: 'white',
    backgroundSize: '100% 100vh',
    padding: '0',
    margin: '0',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'auto 100vh',
    },
  },
  TopBar: {
    color: 'white',
    backgroundColor: 'white',
    boxShadow: '0px -5px 10px #1f1f1f',
    flexGrow: 1,
  },
  demoButton: {
    transition: 'all 1s ease-out',
    transform: 'scale(0,0)',
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1,1)',
    }
  },
  Landing: {
    color: 'white',
    position: 'relative',
    height: '100vh',
  },
  LandingTitle: {
    letterSpacing: '0',
    fontWeight: 900,
    [theme.breakpoints.only('xs')]: {
      fontSize: '30px',
    },
  },
  landingButton: {
    fontWeight: 400,
    backgroundColor: '#00A99D',
    fontFamily: 'roboto, arial',
    color: 'white',
    borderRadius: '4px',
    transition: 'all .4s ease-out',
    '&:hover': {
      transform: 'scale(1.05,1.05)',
      backgroundColor: '#00A99D',
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: '#ffffff',
    },
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar className={classes.TopBar} position="absolute" elevation={0}>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">
              <img alt="logo" src="/logoLanding.png"
                   style={{color: 'white', height: '60px', marginTop: '10px'}}/>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button size="small"
                        variant="contained"
                        color="primary"
                        style={{borderRadius: '4px'}}
                        href="tel:+393392380438">
                        Contattaci
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
const Title = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <section>
      <Box paddingTop="84px">
        <Toolbar/>
        <Container>
          <Grid container direction="row">
            <Grid item xs={12} md={6}>
              <Box>
                <Grid container direction="column" spacing={3} alignItems="flex-start">
                  <Grid item>
                      <Box paddingTop="60px">
                        <Typography variant="h3" align="left" className={classes.LandingTitle}>
                          Benvenuto al Lido Riviera
                        </Typography>
                      </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{borderLeft: 'solid rgba(0,0,0,0.6) 2px'}}>
                      <Box pl="10px">
                        <Typography>
                          Dal 1991 balneazione di qualità
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Button
                        className={classes.landingButton}
                        color="primary"
                        href="tel:+393392380438"
                      >
                        <Container>
                          Contattaci
                        </Container>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img alt="logo" src="/logo512.png"
                   style={{
                     color: 'white', width: '90%',
                     display: 'block',
                     marginLeft: 'auto',
                     marginRight: 'auto',
                   }}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

const GoogleMaps = () => {
  return (
    <Box p={2}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.855349890751!2d13.338609415400244!3d41.29025250997396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132525489588da1f%3A0x3f5959d27fb4f494!2sLido%20Riviera!5e0!3m2!1sen!2sit!4v1626609379539!5m2!1sen!2sit"
        width="100%" height="300px" style={{borderRadius: '16px', border: 0, boxShadow: ' -1px -1px 8px 0px rgba(30, 30, 30, 0.2)'}}  loading="lazy">
      </iframe>
    </Box>
  )
}

const Landing = () => {
  const classes = useStyles();
  return (
    <div className={classes.Page}>
      <TopBar/>
      <Title/>
      <GoogleMaps/>
      <Footer/>
    </div>
  );
};

export default Landing;
