export const credential = {
  apiKey: "AIzaSyBgBs2_Vlk-IZ3ynCVinEr2d6txrjkkMcg",
  authDomain: "piggy-bank-webapp.firebaseapp.com",
  projectId: "piggy-bank-webapp",
  storageBucket: "piggy-bank-webapp.appspot.com",
  messagingSenderId: "208499503696",
  appId: "1:208499503696:web:6da24dc368beb007821755",
  measurementId: "G-HEKEVGZP12"
};


