import React, {useState} from 'react';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import NoSsr from '@material-ui/core/NoSsr';
import {CssBaseline, ThemeProvider as MuiThemeProvider} from '@material-ui/core';
import {deepOrange, deepPurple, lightBlue, orange, teal} from "@material-ui/core/colors";

type FontType = {
  family?: string,
  size?: number,
}

export const ThemeContext = React.createContext({
  theme: 'light' as string,
  setTheme: (theme: string) => {
  },
  font: {
    family: 'Lexend',
    size: 16,
  } as FontType,
  setFont: (font: FontType) => {
  },
});

const Theme: React.FC = ({children}) => {
  const [theme, setTheme] = useState('light');
  const [font, setFont] = useState({family: 'Lexend, sans-serif', size: 16} as FontType);
  const themeValue = {theme, setTheme, font, setFont};
  const palletType = theme === 'dark' ? 'dark' : 'light';  //if dark set darkTheme else lightTheme for light and sepia
  const mainPrimaryColor = teal[400];
  const mainSecondaryColor = orange[300];
  const backgroundPaper = theme === 'light' ? '#ffffff' : theme === 'sepia' ? '#f8f0e3' : '#424242';
  const backgroundDefault = theme === 'light' ? '#fafafa' : theme === 'sepia' ? '#f8f0e3' : '#303030';
  // @ts-ignore
  const materialTheme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor
      },
      secondary: {
        main: mainSecondaryColor
      },
      background: {
        default: backgroundDefault,
        paper: backgroundPaper,
      }
    },
    typography: {
      fontFamily: font.family,
    },
  });
  return (
    <NoSsr>
      <ThemeContext.Provider value={themeValue}>
        <MuiThemeProvider theme={materialTheme}>
            <CssBaseline/>
            {children}
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </NoSsr>
  );
};

export default Theme;
