import React, {useEffect, useState} from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonIcon from '@material-ui/icons/Person';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import EuroRoundedIcon from '@material-ui/icons/EuroRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from 'react-router-dom';
import {
  Avatar,
  Box, Button,
  Container, Divider, FormControl,
  Grid, IconButton, InputAdornment, InputLabel, Link,
  makeStyles, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
  Typography
} from "@material-ui/core";
import {teal, red} from '@material-ui/core/colors';
import TopBar from "../../../Topbar/TopBar";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontWeight: 800,
  },
  price: {
    fontFamily: 'lexend',
    fontWeight: 300,
  },
  avatar: {
    backgroundColor: teal[400],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  subtitle: {},
}));

type UserPageType = {
  uid: string;
}

const UserPage: React.FC<UserPageType> = ({uid}) => {
  const location = useLocation();
  const classes = useStyles();
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [chargeAmount, setChargeAmount] = useState(0.00);
  const [chargeError, setChargeError] = useState(false);
  const [debt, setDebt] = useState(100.00);
  const handleSetChargeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChargeAmount(Number(event.target.value));
  };
  const history = useHistory();
  return (
    <TopBar>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={10}>
            <Box paddingTop={16}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box paddingBottom={1}>
                    <Avatar className={classes.avatar}>
                      <PersonIcon/>
                    </Avatar>
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h4" className={classes.title}>
                        Francesco Amato
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton>
                        <EditOutlinedIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.price}>
                    -189.74€
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box paddingTop={6}>
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.subtitle}
                        color="textSecondary"
                      >
                        New transaction
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="search-tag">Charge</InputLabel>
                        <OutlinedInput
                          id="search"
                          error={chargeError}
                          autoFocus
                          inputProps={{min: 0.00, style: {textAlign: 'right'}}}
                          name="transaction-amount"
                          label="charge"
                          type="name"
                          value={chargeAmount.toFixed(2)}
                          onChange={handleSetChargeAmount}
                          startAdornment={
                            <InputAdornment position="start">
                              €
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Button
                            color="primary"
                            fullWidth
                            onClick={() => setChargeAmount(chargeAmount + 1)}
                          >
                            +1€
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="primary"
                            fullWidth
                            onClick={() => setChargeAmount(chargeAmount + 0.5)}
                          >
                            +0.50€
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="primary"
                            fullWidth
                            onClick={() => setChargeAmount(chargeAmount + 0.1)}
                          >
                            +0.10€
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Box color="error.main">
                            <Button
                              color="inherit"
                              fullWidth
                              onClick={() => setChargeAmount(0)}
                            >
                              AC
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        fullWidth
                        startIcon={
                          <EuroRoundedIcon/>
                        }
                      >
                        Charge
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.subtitle}
                        color="textSecondary"
                      >
                        Transaction history
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider/>
                      <TableContainer>
                        <Table size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <IconButton size="small">
                                  <EuroRoundedIcon fontSize="small" color="primary"/>
                                </IconButton>
                              </TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={1}>
                              <TableCell>1</TableCell>
                              <TableCell component="th" scope="row">15.00€</TableCell>
                              <TableCell>21/06/2021 - 12:30</TableCell>
                            </TableRow>
                            <TableRow key={2}>
                              <TableCell>2</TableCell>
                              <TableCell component="th" scope="row">4.00€</TableCell>
                              <TableCell>20/06/2021 - 10:30</TableCell>
                            </TableRow>
                            <TableRow key={3}>
                              <TableCell>3</TableCell>
                              <TableCell component="th" scope="row">55.00€</TableCell>
                              <TableCell>27/06/2021 - 12:23</TableCell>
                            </TableRow>
                            <TableRow key={4}>
                              <TableCell>...</TableCell>
                              <TableCell component="th" scope="row">...</TableCell>
                              <TableCell>
                                <Link
                                  color="primary"
                                  onClick={() => history.push('/t')}
                                >
                                  show all
                                </Link>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </TopBar>
  );
}

export default UserPage;

