import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {Box, Divider, Link, List, ListItem, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import React, {ReactNode, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'white',
    minWidth: '100vw',
    margin: 0,
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/landing">
        Lido Riviera
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const Footer = () => {
  const classes = useStyles();
  return (
      <Box component="footer" className={classes.footer}>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              {/*<img alt="logo" src="/logoLanding.png" style={{color: 'white', height: '40px', marginTop: '10px'}}/>*/}
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {/*social icons*/}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Divider variant="fullWidth"/>
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={6}>
            <List dense subheader={<ListSubheader>Contatti</ListSubheader>}>
              <ListItem>
                <ListItemIcon>
                  <MailOutlineIcon/>
                </ListItemIcon>
                <ListItemText>
                  <Link color="textPrimary" href="mailto:info@lidoriviera.it">
                    info@lidoriviera.it
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CallOutlinedIcon/>
                </ListItemIcon>
                <ListItemText>
                  <Link color="textPrimary" href="tel:+393392380438">
                    +39 339 238 0438
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  < PlaceIcon/>
                </ListItemIcon>
                <ListItemText>
                  <Link href="https://g.page/Lidoriviera?share" color="textPrimary">
                    Fondi, Via Salto Primo, LT
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Divider variant="fullWidth"/>
        <Box paddingY={2}>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Copyright/>
            </Grid>
          </Grid>
        </Box>
      </Box>
  );
};

export default Footer;
