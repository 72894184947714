import React from 'react';
// @ts-ignore
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import {
  Box, Button,
  Container,
  FormControl,
  Grid, IconButton,
  InputAdornment, InputLabel,
  makeStyles, OutlinedInput,
  Typography
} from "@material-ui/core";
import TopBar from "../../../Topbar/TopBar";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    fontWeight: 800,
  },
  bottomButton: {
    position: 'fixed',
    left: 0,
    bottom: '16px'
  }
}));

function SearchPage() {
  const classes = useStyles();
  return (
    <TopBar>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <Box paddingTop={32}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h4" className={classes.title}>
                    Tag cliente
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="search-tag">Tag</InputLabel>
                    <OutlinedInput
                      autoFocus
                      name="customer-tag"
                      id="search"
                      label="tag"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                          >
                            <SearchRoundedIcon/>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Container className={classes.bottomButton}>
          <Grid container spacing={1} justify="center">
            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                color="primary"
                startIcon={<AddIcon/>}
              >
                add
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                color="primary"
                startIcon={<ListIcon/>}
              >
                List
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </TopBar>
  );
}

export default SearchPage;

