import firebase from "firebase/app";
import React from "react";
import {credential} from "./authCredential";

export const firebaseConfig = {
  apiKey: credential.apiKey,
  authDomain: credential.authDomain,
  projectId: credential.projectId,
  storageBucket: credential.storageBucket,
  messagingSenderId: credential.messagingSenderId ,
  appId: credential.appId,
  measurementId: credential.measurementId,
};

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export const firebaseAuth = firebaseApp.auth();
export const firebaseLogin = (email, password) => {
  firebaseAuth.signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const user = userCredential.user;
    })
    .catch((error) => {
      console.log(error.message)
    });
}



