// @ts-ignore
import {
  Route, Redirect, BrowserRouter as Router, Switch as DefaultSwitch
} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import Theme, {ThemeContext} from "../Theme/Theme";
import SearchPage from "./Public/SearchPage/SearchPage";
import UserPage from "./Public/UserPage/UserPage";
import TransactionsPage from "./Public/TransactionsPage/TransactionsPage";
import SignInSide from "./Public/login/login";
import Landing from "./Public/Landing/Landing";
import "firebase/auth";
import firebase from "firebase/app";

type PrivateRouterType = {
  isLogged: boolean,
  path: string,
}

export const Auth = React.createContext(
  {
    isLogged: false as boolean,
    setIsLogged: (isLogged: boolean) => {
    },
  }
);

const PrivateRoute: React.FC<PrivateRouterType> = (
  {
    isLogged,
    path,
    children
  }
) => {
  return (
    <Route path={path}>
      {isLogged ? children : <Redirect to="/login"/>}
    </Route>
  );
};

const Routes = () => {
  const [isLogged, setIsLogged] = useState(false);
  const authValue = {isLogged, setIsLogged};
  useEffect(()=>{

  })
  return (
    <Auth.Provider value={authValue}>
      <Theme>
        <Router>
          <DefaultSwitch>
            <Route path="/login">
              <SignInSide/>
            </Route>
            <Route path="/landing">
              <Landing/>
            </Route>
            <PrivateRoute isLogged={isLogged} path="/search">
              <SearchPage/>
            </PrivateRoute>
            <PrivateRoute isLogged={isLogged} path="/u">
              <UserPage uid="abcdef"/>
            </PrivateRoute>
            <PrivateRoute isLogged={isLogged} path="/t">
              <TransactionsPage uid="abcdef"/>
            </PrivateRoute>
            <Route path="/">
              <Redirect to="/landing"/>
            </Route>
          </DefaultSwitch>
        </Router>
      </ Theme>
    </Auth.Provider>
  );
}

export default Routes;
