import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import {
  Box, Button,
  Container,
  Grid, IconButton,
  makeStyles,
  Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Link,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import {teal} from "@material-ui/core/colors";
import EuroRoundedIcon from "@material-ui/icons/EuroRounded";
import TopBar from "../../../Topbar/TopBar";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontWeight: 300,
  },
  price: {
    fontWeight: 800,
  },
  avatar: {
    backgroundColor: teal[400],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

type TransactionsPageType = {
  uid: string;
}

const TransactionsPage: React.FC<TransactionsPageType> = ({uid}) => {
  const classes = useStyles();
  return (
    <TopBar>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <Box paddingTop={16}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Typography variant="h3" className={classes.price}>
                    -189.74€
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary" className={classes.title}>
                    Francesco Amato
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box paddingTop={4}>
              <Grid container spacing={8} alignItems="center">
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <IconButton size="small">
                              <EuroRoundedIcon fontSize="small" color="primary"/>
                            </IconButton>
                          </TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>1</TableCell>
                          <TableCell component="th" scope="row">15.00€</TableCell>
                          <TableCell>21/06/2021 - 12:30</TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>2</TableCell>
                          <TableCell component="th" scope="row">4.00€</TableCell>
                          <TableCell>20/06/2021 - 10:30</TableCell>
                        </TableRow>
                        <TableRow key={3}>
                          <TableCell>3</TableCell>
                          <TableCell component="th" scope="row">55.00€</TableCell>
                          <TableCell>27/06/2021 - 12:23</TableCell>
                        </TableRow>
                        <TableRow key={4}>
                          <TableCell>3</TableCell>
                          <TableCell component="th" scope="row">55.00€</TableCell>
                          <TableCell>27/06/2021 - 12:23</TableCell>
                        </TableRow>
                        <TableRow key={5}>
                          <TableCell>3</TableCell>
                          <TableCell component="th" scope="row">55.00€</TableCell>
                          <TableCell>27/06/2021 - 12:23</TableCell>
                        </TableRow>
                        <TableRow key={6}>
                          <TableCell>3</TableCell>
                          <TableCell component="th" scope="row">55.00€</TableCell>
                          <TableCell>27/06/2021 - 12:23</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={8}>
                  <Box color="error.main">
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      startIcon={<AttachMoneyOutlinedIcon/>}
                    >
                      settle the debt
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </TopBar>
  );
}

export default TransactionsPage;

